<template>
  <div id="nav">
    <div style="display: flex; justify-content: space-between">
      <div id="leftColMainHeader">
        <router-link to="/">
          <img src="/assets/Icon.svg" class="apptalk-icon-navbar" />
        </router-link>
      </div>

      <div id="centerColMainHeader">
        <div id="nav-logo">
          <router-link to="/">
            <img :src="logo" :alt="alt" id="logo" />
          </router-link>
        </div>
      </div>

      <div id="RightColMainHeader" >
        <div id="right-icons">
        
            <div class="indicatorNotification" v-if="notificationsAdv"></div>
            <div class="indicatorNotification" v-if="this.unreadNotificationCount > 0">{{  this.unreadNotificationCount  }}</div>
    
          
          <div class="right-button marginRightChild" @click="this.displayNotificationsMethod()">
            <svg width="28" height="28" viewBox="0 0 24 24" class="right-button-icon">
              <path
                d="M9.042 19.003h5.916a3 3 0 0 1-5.916 0Zm2.958-17a7.5 7.5 0 0 1 7.5 7.5v4l1.418 3.16A.95.95 0 0 1 20.052 18h-16.1a.95.95 0 0 1-.867-1.338l1.415-3.16V9.49l.005-.25A7.5 7.5 0 0 1 12 2.004Z" />
            </svg>
          </div>
          <div class="indicatorProfile" v-if="this.needEdit == true"></div>
          <div class="right-button" @click="displayConfig">
            <svg width="28" height="28" viewBox="0 0 24 24" class="right-button-icon">
              <path
                d="M12.013 2.25c.734.008 1.465.093 2.181.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.928 1.116l1.4-.615a.75.75 0 0 1 .85.174a9.793 9.793 0 0 1 2.204 3.792a.75.75 0 0 1-.271.825l-1.242.916a1.38 1.38 0 0 0 .001 2.226l1.243.915a.75.75 0 0 1 .271.826a9.798 9.798 0 0 1-2.203 3.792a.75.75 0 0 1-.849.175l-1.406-.617a1.38 1.38 0 0 0-1.927 1.114l-.169 1.526a.75.75 0 0 1-.572.647a9.518 9.518 0 0 1-4.405 0a.75.75 0 0 1-.572-.647l-.17-1.524a1.382 1.382 0 0 0-1.924-1.11l-1.407.616a.75.75 0 0 1-.849-.175a9.798 9.798 0 0 1-2.203-3.796a.75.75 0 0 1 .271-.826l1.244-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.272-.826a9.793 9.793 0 0 1 2.205-3.792a.75.75 0 0 1 .849-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.718-.159 1.45-.243 2.202-.252ZM12 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
            </svg>
          </div>

          <Notifications @hasNotification="turnOnNotifications" v-if="this.displayNotifications" />

          <div class="optionsConfig" v-if="this.config">
            <div class="option" v-if="this.allRepresentatives > 0" @click="this.changeProfile()">
              <div style="
                  background-color: var(--red);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-right: 11px;
                " v-if="this.needEdit == true"></div>

              <div class="option-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <path fill="#595959"
                    d="M10 12q-1.65 0-2.825-1.175Q6 9.65 6 8q0-1.65 1.175-2.825Q8.35 4 10 4q1.65 0 2.825 1.175Q14 6.35 14 8q0 1.65-1.175 2.825Q11.65 12 10 12Zm-7 8q-.425 0-.712-.288Q2 19.425 2 19v-1.8q0-.825.425-1.55q.425-.725 1.175-1.1q1.275-.65 2.875-1.1Q8.075 13 10 13h.35q.15 0 .3.05q-.2.45-.338.938q-.137.487-.212 1.012H10q-1.775 0-3.188.45q-1.412.45-2.312.9q-.225.125-.362.35q-.138.225-.138.5v.8h6.3q.15.525.4 1.038q.25.512.55.962Zm7-10q.825 0 1.413-.588Q12 8.825 12 8t-.587-1.412Q10.825 6 10 6q-.825 0-1.412.588Q8 7.175 8 8t.588 1.412Q9.175 10 10 10Zm0-2Zm.3 10Zm6.7 0q.825 0 1.413-.587Q19 16.825 19 16q0-.825-.587-1.413Q17.825 14 17 14q-.825 0-1.412.587Q15 15.175 15 16q0 .825.588 1.413Q16.175 18 17 18Zm-1.3 1.5q-.3-.125-.563-.262q-.262-.138-.537-.338l-1.075.325q-.175.05-.325-.013q-.15-.062-.25-.212l-.6-1q-.1-.15-.062-.325q.037-.175.187-.3l.825-.725q-.05-.35-.05-.65q0-.3.05-.65l-.825-.725q-.15-.125-.187-.3q-.038-.175.062-.325l.6-1q.1-.15.25-.213q.15-.062.325-.012l1.075.325q.275-.2.537-.338q.263-.137.563-.262l.225-1.1q.05-.175.175-.287q.125-.113.3-.113h1.2q.175 0 .3.113q.125.112.175.287l.225 1.1q.3.125.563.275q.262.15.537.375l1.05-.375q.175-.075.338 0q.162.075.262.225l.6 1.05q.1.15.075.325q-.025.175-.175.3l-.85.725q.05.3.05.625t-.05.625l.825.725q.15.125.187.3q.038.175-.062.325l-.6 1q-.1.15-.25.212q-.15.063-.325.013L19.4 18.9q-.275.2-.537.338q-.263.137-.563.262l-.225 1.1q-.05.175-.175.287q-.125.113-.3.113h-1.2q-.175 0-.3-.113q-.125-.112-.175-.287Z" />
                </svg>
              </div>
              <div>
                <span>
                  {{ $t("changeProfile") }}
                </span>
              </div>
            </div>
            <div class="option" v-if="this.allRepresentatives > 0" @click="this.changeCompany()">
              <div class="option-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                  <path fill="#595959"
                    d="M12.78 3.22a.75.75 0 1 0-1.06 1.06L13.44 6H4.75a.75.75 0 0 0 0 1.5h8.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3Zm-4.5 7.56a.75.75 0 1 0-1.06-1.06l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06L6.56 14h8.69a.75.75 0 0 0 0-1.5H6.56l1.72-1.72Z" />
                </svg>
              </div>
              <div>
                <span>
                  {{ $t("changeCompany") }}
                </span>
              </div>
            </div>
            <div class="option" @click="this.logout()">
              <div class="option-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                  <path fill="#505050"
                    d="M12.5 17a.5.5 0 0 0 0-1H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6.5a.5.5 0 0 0 0-1H6a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h6.5Zm1.146-10.854a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708l2.647-2.646H7.5a.5.5 0 0 1 0-1h8.793l-2.647-2.646a.5.5 0 0 1 0-.708Z" />
                </svg>
              </div>
              <div>
                <span>
                  {{ $t("logout") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsService from "@/services/notifications.service";
import UserService from "@/services/user.service";
import { useAuth } from "@/store/auth.js";
import Notifications from "./notification/Notifications.vue";

export default {
  name: "Navbar",
  props: {
    alt: "",
    logo: "",
    route: "",
    routeName: "",
    userImage: "",
    allRepresentatives: "",
  },

  components: {
    Notifications,
  },

  data() {
    return {
      selected: "selected",
      selectedDiv: "selected-div",
      config: false,
      displayNotifications: false,
      notificationsAdv: false,
      needEdit: false,
      dataNotificationUser: [],
      unreadNotificationCount: 0,
    };
  },

  beforeMount() {
    this.getNeedEdit();
    this.NewNotificationUser();
  },

  methods: {
    getNeedEdit() {
      // UserService.checkInfoStatus().then(data => {
      //   // console.log(data.data)
      //   this.needEdit = data.data
      // })
    },


    NewNotificationUser() {
      NotificationsService.getNewNotifications().then((data) => {
        this.dataNotificationUser = data.data;
        this.unreadNotificationCount = this.dataNotificationUser.filter(notification => notification.status_notify === 0).length;
      });
    },

    displayConfig() {
      this.config = !this.config;
      this.displayNotifications = false;
    },

    displayNotificationsMethod() {
      this.displayNotifications = !this.displayNotifications;

      this.config = false;
      this.notificationsAdv = false;

    },
    changeProfile() {
      this.$emit("openProfileModal");
    },

    turnOnNotifications() {
      this.notificationsAdv = true;
    },

    logout() {
      const auth = useAuth();
      auth.logoutUser();
      this.$router.push("/login");
    },

    changeCompany() {
      // console.log('ChangeCompany');
      this.$emit("openMultiModal");
    },
  },
};
</script>

<style scoped>
.indicatorNotification {
  background-color: var(--red);
  min-width: 13px;
  min-height: 13px;
  position: absolute;
  top: 0px;
  border-radius: 50%;
  margin-left: -21px;
  color: #ffff;
  font-weight: 600;
  padding: 3px 3px 3px 3px;
  font-size: 12px;
}

.indicatorProfile {
  width: 13px;
  background-color: var(--red);
  height: 13px;
  position: absolute;
  top: 0px;
  right: -1px;
  border-radius: 50%;
  margin-left: -21px;
}

.apptalk-icon-navbar {
  width: 45px;
  height: 45px;
  margin-left: 1px;
  margin-top: 2px;
  cursor: pointer;
}

.mainHeader {
  margin: auto;
}

.marginRightChild {
  margin-right: 10px;
}

#leftColMainHeader {
  display: flex;
  justify-content: left;
  align-items: center;
}

#RightColMainHeader {
  display: flex;
  justify-content: right;
}

#centerColMainHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 220px;
  max-height: 45px;
  max-height: 45px;
  -o-object-fit: contain;
  object-fit: contain;
}

.userProfilePic {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  object-fit: cover;
}

#nav-buttons {
  position: absolute;
  height: 100%;
  display: flex;
}

.nav-button {
  display: flex;
}

.selected-div {
  display: flex;
  border-bottom: 3px solid var(--yellow);
}

.svg-icon {
  fill: var(--dark-grey);
  border-bottom: 3px solid transparent;
  margin-top: 1.5px;
}

.selected {
  fill: var(--yellow);
  border-bottom: 3px solid transparent;
  margin-top: 1.5px;
}

.icon-nav {
  margin: 28px;
  margin-top: 10px;
}

#nav {
  background-color: white;
  padding: 5px 18px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

#nav-user {
  background-color: var(--dark-grey);
  border-radius: 100%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

#right-icons {
  display: flex;
  justify-content: center;
  position: relative;
}

.right-button-icon {
  fill: var(--light-grey);
}

.right-button {
  background-color: var(--dark-grey);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.optionsConfig {
  border: 1px solid var(--dark-grey);
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--white);
  margin-top: 58px;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  width: 220px;
}

.option {
  display: flex;
  float: right;
  align-items: center;
  width: 100%;
  height: 42px;
  cursor: pointer;
  color: #505050;
}

.option-img {
  margin-right: 10px;
  margin-top: -2.8px;
}
</style>
