<template>
  <div>
    <div class="mb-1 gray-bg" v-if="!showFlag">
      <p
        class="mb-1"
        v-html="convertUrlsToLinks(longText.slice(0, 192) + '...')"
      ></p>
      <span @click="showFlag = true" class="spanChange">
        {{ $t("seeMore") }}
      </span>
    </div>

    <div class="mb-1 gray-bg" v-if="showFlag">
      <p class="mb-1" v-html="convertUrlsToLinks(longText)"></p>
      <span @click="showFlag = false" class="spanChange">{{
        $t("seeLess")
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Readmore",
  data() {
    return {
      readMoreActivated: false,
      showFlag: false,
    };
  },
  props: {
    longText: "",
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    sliceText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },

    convertUrlsToLinks(text) {
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlPattern, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },

    convertUrlsToLinks(text) {
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      const textWithLinks = text.replace(urlPattern, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
      return textWithLinks.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
};
</script>

<style>
.spanChange {
  margin-left: 2px;
  color: var(--yellow);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
